<template>
  <div>
    <b-overlay :show="show_loader" rounded="sm">
      <b-card-code :title="$t('User.user_list') + ' - ' + $t('User.Editors')">
        <b-button
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          variant="outline-primary"
          class="mt-0 mt-md-2"
          @click="addEditor()"
        >
          <feather-icon icon="PlusIcon" class="mr-25" />{{
            $t("User.Add_editor")
          }}
        </b-button>

        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">{{ $t("search") }}</label>
              <b-form-input
                v-model="searchTerm"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <vue-good-table
          :columns="columns"
          :rows="users"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          styleClass="vgt-table table-hover"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: id -->
            <span v-if="props.column.field == 'id'">
              <span> {{ props.row.id }}</span>
            </span>
            <!-- Column: avatar -->
            <span v-if="props.column.field == 'avatar'">
              <b-avatar
                size="32"
                :text="avatarText(props.row.name + ' ' + props.row.last_name)"
              />
            </span>
            <!-- Column: name -->
            <span v-if="props.column.field == 'name'">
              <span> {{ props.row.name }}</span>
            </span>
            <!-- Column: Last Name -->
            <span v-if="props.column.field == 'last_name'">
              <span> {{ props.row.last_name }}</span>
            </span>
            <!-- Column: email -->
            <span v-if="props.column.field == 'email'">
              <span> {{ props.row.email }}</span>
            </span>
            <!-- Column: state -->
            <span v-if="props.column.field == 'state'">
              <span>
                <b-badge pill v-if="props.row.state" variant="success">
                  {{ $t("User.Active") }}
                </b-badge>
                <b-badge pill v-else variant="warning">
                  {{ $t("User.Invited") }}
                </b-badge>
              </span>
            </span>
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  v-if="props.row.state"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="deleteEditor(props.row.id)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span> {{ $t("User.Delete") }} </span>
                  </b-dropdown-item>
                </b-dropdown>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  v-else
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="resendInvite(props.row.id)">
                    <feather-icon icon="MailIcon" class="mr-50" />
                    <span> {{ $t("User.Resend") }} </span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteInvite(props.row.id)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span> {{ $t("User.Delete") }} </span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  {{ $t("showing") }}
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3', '5', '10']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap">
                  {{ $t("showing_pt2", { total_entries }) }}
                </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
        <div class="custom-search d-flex justify-content-end"></div>
      </b-card-code>
    </b-overlay>
    <modal-editor :id="id" @onSave="refreshEditor"> </modal-editor>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BFormGroup,
  BFormInput,
  BButton,
  BPagination,
  BFormSelect,
  BBadge,
  BOverlay,
  BAvatar,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { avatarText } from "@core/utils/filter";
import "vue-good-table/dist/vue-good-table.css";
import Ripple from "vue-ripple-directive";
import ModalEditor from "./edit-editor.vue";

import {
  getAllUsers,
  getInvitations,
  invite,
  deleteInvitations,
  resendInvitations,
  deleteUser,
} from "@api/user";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BPagination,
    BOverlay,
    BBadge,
    BAvatar,
    BCardCode,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    ModalEditor,
  },
  setup() {
    return { avatarText };
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show_loader: true,
      columns: [
        {
          label: "id",
          field: "id",
          hidden: true,
        },
        {
          field: "avatar",
          sortable: false,
          width: "50px",
        },
        {
          label: this.$t("User.Name"),
          field: "name",
        },
        {
          label: this.$t("User.Last_name"),
          field: "last_name",
        },
        {
          label: this.$t("User.Email"),
          field: "email",
        },
        {
          label: this.$t("User.State"),
          field: "state",
        },
        {
          field: "action",
          label: this.$t("field_label_actions"),
          sortable: false,
          width: "90px",
        },
      ],
      users: [],
      searchTerm: "",
      pageLength: 10,
      total_entries: null,
      id: null,
      role: "editor",
    };
  },
  created() {
    this.getData(this.role);
  },
  mounted() {
    document.getElementById(
      "breadcrumbsPageTitle"
    ).innerHTML = this.$route.meta.pageTitle;
  },
  methods: {
    addEditor() {
      this.id = null;
      this.$bvModal.show("modal-editor");
    },
    deleteEditor(id) {
      const $this = this;
      this.$swal({
        title:
          '<span class="font-weight-bolder">' +
          this.$t("alert_title_warning") +
          "</span>",
        icon: "warning",
        text: this.$t("User.alert_content_text"),
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: this.$t("alert_confirm_button"),
        cancelButtonText: this.$t("alert_cancel_button"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            this.$swal({
              icon: "success",
              title: this.$t("alert_title_success"),
              text: this.$t("User.alert_success_text"),
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
            deleteUser(id)
              .then(() => {
                $this.refreshEditor();
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteInvite(id) {
      const $this = this;
      this.$swal({
        title:
          '<span class="font-weight-bolder">' +
          this.$t("alert_title_warning") +
          "</span>",
        icon: "warning",
        text: this.$t("User.alert_content_text"),
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: this.$t("alert_confirm_button"),
        cancelButtonText: this.$t("alert_cancel_button"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            this.$swal({
              icon: "success",
              title: this.$t("alert_title_success"),
              text: this.$t("User.alert_success_text"),
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
            deleteInvitations(id)
              .then(() => {
                $this.refreshEditor();
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resendInvite(id) {
      resendInvitations(id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Invito",
              icon: "AlertOctagonIcon",
              text: "Invito inviato con successo",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Errore",
              icon: "AlertOctagonIcon",
              text: "Impossibile inviare invito, riprovare più tardi",
              variant: "danger",
            },
          });
        });
    },
    refreshEditor() {
      this.getData(this.role);
    },
    getData(role) {
      const $this = this;
      this.show_loader = true;
      getAllUsers(role)
        .then((data) => {
          $this.users = data.map((item) => {
            return {
              id: item.id,
              name: item.name,
              last_name: item.last_name,
              email: item.email,
              state: true,
            };
          });
          getInvitations()
            .then((data) => {
              let subscription = data.map((item) => {
                return {
                  id: item.id,
                  name: "",
                  last_name: "",
                  email: item.email,
                };
              });
              $this.users = [...$this.users, ...subscription];
              $this.total_entries = data.length;
              $this.show_loader = false;
            })
            .catch((error) => {
              console.log(error);
              $this.show_loader = false;
            });
        })
        .catch((error) => {
          console.log(error);
          $this.show_loader = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>
